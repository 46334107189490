import React from 'react'

import { Brand, CTA, Navbar } from './components';
import { Footer, Header, Possibility, Features, Whatgpt3, Blog } from './containers';
import './App.css';

const App = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
            <Navbar />
            <Header />
            </div>
            <Brand />
            <Whatgpt3 />
            <Features />
            <Possibility />
            <CTA />
            <Blog />
            <Footer />
        </div>
    )
}

export default App
